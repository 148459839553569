
export const getPrice = (salePrice: any, price: any) => {
    if (salePrice && price>salePrice) {
      return (
        <div className="price">
          <span>Price:</span>
          <div className="d-flex align-items-center">
            <h2>${salePrice && salePrice.toFixed(2)}</h2>
          </div>
          <div style={{ fontSize: ".875rem", fontWeight: 500 }}>
            List Price <s>${price.toFixed(2)}</s>
          </div>
          <div style={{ fontSize: ".875rem", fontWeight: 500, display: "flex" }}>
            {salePrice && salePrice && price ? (
              <>
                <span>Save ${(price - salePrice).toFixed(2)}&nbsp;&nbsp;</span>
                <span style={{ color: "red" }}>
                  ({(((price - salePrice) / price) * 100).toFixed(2)}% off)
                </span>
              </>
            ) : null}
          </div>
        </div>
      );
    }
  
    return (
      <div className="price">
        <span>Price:</span>
        <div className="d-flex align-items-center">
          <h2>${price}</h2>
        </div>
      </div>
    );
  };


  export const getSinglePrice = (item:any) => {
    if( item.saleprice && item.saleprice !== undefined  && item.saleprice.length>0 && item.saleprice[0] !== null) {
      return `${Number(item?.saleprice[0])}`;
    }
      return  item?.price && item?.price[0]
  }