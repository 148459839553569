import { all, fork } from "redux-saga/effects";
import paymentSaga from "./paymentSaga";
import userSaga from "./userSaga";
import categorySaga from "./categorySaga";
import cartSaga from "./cartSaga";
import wishListSaga from "./wishListSaga";
import addressSaga from "./addressSaga";
import accountSettingSaga from "./accountSettingsSaga";
import orderSaga from "./orderSaga";
import productSaga from "./productSaga";
import paypalPayment from "./paypalPaymentSaga";
import kitchen from "./Home/kitchen";
import electronics from "./Home/electronics";
import games from "./Home/games";
import valentine from "./Home/valentine";
import bags from "./Home/bags";
import mobile from "./Home/mobile";
import travel from "./Home/travel";
import shoes from "./Home/shoes";
import stationary from "./Home/stationary";
import rating from "./rating";
import subscribe from "./newsLetterSaga";
import subscriptionSaga from "./subsciptionSaga";
import imageSaga from "./imageSaga";
import sellersSaga from "./sellersSaga";
import sellerShopFilterSaga from "./sellerShopFilterSaga";
import dropdownCategoriesSaga from "./dropdownCategoriesSaga";

export default function* rootSaga() {
  yield all([
    fork(cartSaga),
    fork(categorySaga),
    fork(paymentSaga),
    fork(paypalPayment),
    fork(userSaga),
    fork(wishListSaga),
    fork(addressSaga),
    fork(accountSettingSaga),
    fork(orderSaga),
    fork(productSaga),
    fork(kitchen),
    fork(electronics),
    fork(games),
    fork(valentine),
    fork(bags),
    fork(mobile),
    fork(travel),
    fork(shoes),
    fork(stationary),
    fork(rating),
    fork(subscribe),
    fork(subscriptionSaga),
    fork(imageSaga),
    fork(sellersSaga),
    fork(sellerShopFilterSaga),
    fork(dropdownCategoriesSaga),
  ]);
}
