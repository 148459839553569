export const Animals_and_Pet_Supplies =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Animals_and_Pet_Supplies.webp";
export const Anti_Perspirant =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Anti_Perspirant.webp";
export const Apparel_and_Accessories =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Apparel_and_Accessories.webp";
export const Art_and_Entertainment =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Art_and_Entertainment.webp";
export const Baby_and_toddler =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Baby_and_toddler.webp";
export const Bussiness_and_Industrial =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Bussiness_and_Industrial.webp";
export const Ceremonial =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Ceremonial.webp";
export const Ebook_Reader_Cases =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Ebook_Reader_Cases.webp";
export const Festival_1 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Festival-1.webp";
export const Festival_2 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Festival-2.webp";
export const Festival_3 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Festival-3.webp";
export const Festival_4 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Festival-4.webp";
export const Food_Beverages =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Food_Beverages.webp";
export const Hardware =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Hardware.webp";
export const Health_and_Beauty =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Health_and_Beauty.webp";
export const Luggage_and_Bags =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Luggage_and_Bags.webp";
export const Mature =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Mature.webp";
export const Mobile_Prepaid_Cards =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Mobile_Prepaid_Cards.webp";
export const Software =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Software.webp";
export const Sporting_Goods =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Sporting_Goods.webp";
export const Vehicles_and_Parts =
  "https://storage.googleapis.com/content.paysfer.com/assets2/Vehicles_and_Parts.webp";
export const Ab1 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/ab-1.webp";
export const Ab2 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/ab-2.webp";
export const Advertisement =
  "https://storage.googleapis.com/content.paysfer.com/assets2/advertisement.webp";
export const Bg =
  "https://storage.googleapis.com/content.paysfer.com/assets2/bg.webp";
export const Cap =
  "https://storage.googleapis.com/content.paysfer.com/assets2/cap.webp";
export const Device =
  "https://storage.googleapis.com/content.paysfer.com/assets2/device.webp";
export const Electronics =
  "https://storage.googleapis.com/content.paysfer.com/assets2/electronics.webp";
export const Ereader =
  "https://storage.googleapis.com/content.paysfer.com/assets2/ereader.webp";
export const Facebook =
  "https://storage.googleapis.com/content.paysfer.com/assets2/facebook.webp";
export const Furnitures =
  "https://storage.googleapis.com/content.paysfer.com/assets2/furnitures.webp";
export const Gpay =
  "https://storage.googleapis.com/content.paysfer.com/assets2/g-pay.webp";
export const Gaming =
  "https://storage.googleapis.com/content.paysfer.com/assets2/gaming.webp";
export const Gift1 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/gift-1.webp";
export const Gift2 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/gift-2.webp";
export const Gift3 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/gift-3.webp";
export const Gift4 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/gift-4.webp";
export const Green_house =
  "https://storage.googleapis.com/content.paysfer.com/assets2/green-house.webp";
export const Hbd =
  "https://storage.googleapis.com/content.paysfer.com/assets2/hbd.webp";
export const Heart =
  "https://storage.googleapis.com/content.paysfer.com/assets2/heart.webp";
export const I_pay =
  "https://storage.googleapis.com/content.paysfer.com/assets2/i-pay.webp";
export const Ico_2 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/ico-2.webp";
export const Ico_3 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/ico-3.webp";
export const Instagram =
  "https://storage.googleapis.com/content.paysfer.com/assets2/instagram.webp";
export const Iteam_4 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/iteam-4.webp";
export const Jewelery =
  "https://storage.googleapis.com/content.paysfer.com/assets2/jewelery.webp";
export const Kitchen =
  "https://storage.googleapis.com/content.paysfer.com/assets2/kitchen.webp";
export const Linkedin =
  "https://storage.googleapis.com/content.paysfer.com/assets2/linkedin.webp";
export const Logo =
  "https://storage.googleapis.com/content.paysfer.com/assets2/logo.webp";
export const Office_supplies =
  "https://storage.googleapis.com/content.paysfer.com/assets2/office_supplies.webp";
export const Pay_pal =
  "https://storage.googleapis.com/content.paysfer.com/assets2/pay-pal.webp";
export const PaysferLogoWhite =
  "https://storage.googleapis.com/content.paysfer.com/assets2/paysferLogoWhite.webp";
export const Product_advertisment =
  "https://storage.googleapis.com/content.paysfer.com/assets2/product-advertisment.webp";
export const Product_tracking_img =
  "https://storage.googleapis.com/content.paysfer.com/assets2/product-tracking-img.webp";
export const Shopping_cart =
  "https://storage.googleapis.com/content.paysfer.com/assets2/shopping-cart.webp";
export const Stripe =
  "https://storage.googleapis.com/content.paysfer.com/assets2/stripe.webp";
export const Sunglasses =
  "https://storage.googleapis.com/content.paysfer.com/assets2/sunglasses.webp";
export const Tools =
  "https://storage.googleapis.com/content.paysfer.com/assets2/tools.webp";
export const Toys =
  "https://storage.googleapis.com/content.paysfer.com/assets2/toys.webp";
export const Twitter =
  "https://storage.googleapis.com/content.paysfer.com/assets2/twitter.webp";
export const User_1 =
  "https://storage.googleapis.com/content.paysfer.com/assets2/user-1.webp";
export const User =
  "https://storage.googleapis.com/content.paysfer.com/assets2/user.webp";
export const Vendor_icon =
  "https://storage.googleapis.com/content.paysfer.com/assets2/vendor-icon.webp";
export const Payment_img =
  "https://storage.googleapis.com/content.paysfer.com/banner/paysfer%20payment%20methods%20(1).png";
export const Venmo =
  "https://storage.googleapis.com/content.paysfer.com/assets2/venmo.webp";
export const Verified_user =
  "https://storage.googleapis.com/content.paysfer.com/assets2/verified-user.webp";
export const Video_console =
  "https://storage.googleapis.com/content.paysfer.com/assets2/video-console.webp";
export const New_Logo =
  "https://storage.googleapis.com/content.paysfer.com/banner/white-logo.png"
