// @ts-nocheck
import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import SignIn from "components/Layout/SiteContent/Signin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import useWindowSize from "utils/useWindowSize";
import { updateMobileState } from "redux/mobileLogin";
import "./styles.css";
const MobileViewLogin: React.FC = () => {
  const dispatch = useDispatch();
  const view: any = useSelector(
    (state: RootState) => state.mobileloginstate.view
  );
  const size = useWindowSize();

  useEffect(() => {
    const delayedEffect = setTimeout(() => {
      let accessToken = localStorage.getItem("access-token");
      if (accessToken != null) dispatch(updateMobileState(false));
      else dispatch(updateMobileState(true));
    }, 10000);

    return () => clearTimeout(delayedEffect); // Cleanup the timeout if the component is unmounted
  }, [dispatch]);

  return (
    <div>
      {size.x < 1000 && (
        <Drawer
          anchor="bottom"
          open={view}
          onClose={() => dispatch(updateMobileState(false))}
        >
          <p
            className="closeButton"
            onClick={() => dispatch(updateMobileState(false))}
          >
            X
          </p>

          <SignIn />
        </Drawer>
      )}
    </div>
  );
};

export default MobileViewLogin;
