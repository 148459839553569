import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import { RootState } from "redux/store";
import { getProductsStart } from "redux/sellers";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Modal,
  Tooltip,
  Typography,
  TextField,
  Tab,
  Pagination,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { getSinglePrice } from "../Product/displayPrice";
import { titelize } from "utils/titelize";
import CopyToClipboard from "react-copy-to-clipboard";
import snack from "components/wrapper/snack";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { getReviewBySellerId } from "redux/rating";
import CustomReviewSection from "./customReview";
import { getSellerCategoriesStart } from "redux/sellerShopFilter";
import Drawer from "@mui/material/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ClearButton,
  FilterContainer,
  FilterLabel,
  MobileViewFilterWrapper,
  CardContainer,
} from "./styledComponents";

const ShopDetails = () => {
  const location: any = useLocation();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [tabValue, setTabValue] = useState("1");
  const [shareProductDetails, setShareProductDetails] = useState({
    id: "",
    title: "",
  });
  const [shareModelOpen, setShareModelOpen] = useState(false);
  const [sellerBrandData, setSellerBrandData] = useState<any[]>([]);
  const [sellerCategoriesData, setSellerCategoriesData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<{
    brand: any[];
    categories: any[];
  }>({ brand: [], categories: [] });

  const [filter, setfilter] = useState<boolean>(false);

  const [smallScreenFilter, setSmallScreenFilter] = useState<string | false>(
    false
  );

  const products = useSelector((state: RootState) => state.seller.products);
  const seller = useSelector((state: RootState) => state.seller.seller);
  const sellerCategories = useSelector(
    (state: RootState) => state.sellerShopFilter.sellerFilters.categories
  );
  const sellerBrand = useSelector(
    (state: RootState) => state.sellerShopFilter.sellerFilters.brand
  );
  const reviews = useSelector(
    (state: RootState) => state.rating.sellerReview.review
  );
  const reviewProducts = useSelector(
    (state: RootState) => state.rating.sellerReview.product
  );

  const shareUrl = `${window.location.origin}/product-page/${
    shareProductDetails.id
  }/${titelize(shareProductDetails.title)}`;

  const handleClick = (item: any) => {
    history.push({
      pathname: `/product-page/${item._id}/${titelize(item.title)}`,
      state: {
        item: item,
        src: "from-search",
      },
    });
  };

  const handleSmallScreenFiltersExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSmallScreenFilter(isExpanded ? panel : false);
    };

  const SingleProductLayout = (item: any, index: number) => {
    return (
      <>
        {/* Mobile View */}
        <div className="hideSingleProductLayoutForMobileView">
          <Box className="SingleProductLayoutForMobileView">
            <CardMedia
              image={item.productgroupimageurl1}
              title={item.title}
              sx={{ width: 200 }}
              onClick={() => handleClick(item)}
            />
            <CardContent>
              <Typography
                onClick={() => handleClick(item)}
                sx={{ fontWeight: 400 }}
                gutterBottom
                component="div"
              >
                {item.title}
              </Typography>

              <Typography gutterBottom component="div">
                ${getSinglePrice(item)}&#160;&#160;
                {item.Quantity === undefined ||
                item.Quantity[0] === undefined ||
                item.Quantity[0] === "0" ||
                item.Quantity[0] <= 0
                  ? "Out of Stock"
                  : "Available in Stock"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Free shipping
                <br />
                Free returns
              </Typography>
            </CardContent>
            <CardActions>
              <CardContainer>
                &nbsp;
                <ShareIcon
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                    width: "18px",
                    color: "#EF5051",
                  }}
                  onClick={() => {
                    setShareProductDetails({
                      id: item["_id"],
                      title: item.title,
                    });
                    setShareModelOpen(true);
                  }}
                />
              </CardContainer>
            </CardActions>
          </Box>
          <hr />
        </div>
        {/* Desktop View */}

        <div
          key={item.toString()}
          className="col-md-4 col-lg-3 SingleProductLayoutForDesktop"
        >
          <Card
            sx={{
              maxWidth: 345,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              transition: "1s",
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
                transition: "1s",
              },
            }}
          >
            <CardMedia
              sx={{ height: 250 }}
              image={item.productgroupimageurl1}
              title={item.title}
              onClick={() => handleClick(item)}
            />
            <CardContent sx={{ height: "200px", fontSize: "15px" }}>
              <Typography
                onClick={() => handleClick(item)}
                sx={{ color: "#6cb4ee" }}
                gutterBottom
                component="div"
              >
                {item.title}
              </Typography>

              <Typography gutterBottom component="div">
                ${getSinglePrice(item)}&#160;&#160;
                {item.Quantity === undefined ||
                item.Quantity[0] === undefined ||
                item.Quantity[0] === "0" ||
                item.Quantity[0] <= 0
                  ? "Out of Stock"
                  : "Available in Stock"}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Free shipping
                <br />
                Free returns
              </Typography>
            </CardContent>
            <CardActions>
              <div className="cardActionsContainerForShopDetails">
                &nbsp;&nbsp;
                <ShareIcon
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                    width: "18px",
                    color: "#EF5051",
                  }}
                  onClick={() => {
                    setShareProductDetails({
                      id: item["_id"],
                      title: item.title,
                    });
                    setShareModelOpen(true);
                  }}
                />
              </div>
            </CardActions>
          </Card>
        </div>
      </>
    );
  };

  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setId(location?.state?.item?._id);
  }, [location]);

  useEffect(() => {
    if (params.id) {
      dispatch(getProductsStart({ sellerId: params?.id, page }));
      dispatch(getReviewBySellerId(params?.id));
      dispatch(getSellerCategoriesStart(params?.id));
    }
  }, [params?.id]);

  useEffect(() => {
    let categories: any[] = [];
    sellerCategories.forEach((category: any) => {
      categories.push(category);
    });
    setSellerCategoriesData(categories);
  }, [sellerCategories]);

  useEffect(() => {
    let brands: any[] = [];
    sellerBrand.forEach((brand: any) => {
      brands.push(brand);
    });
    setSellerBrandData(brands);
  }, [sellerBrand]);

  return (
    <div className="shopDetailsContainer">
      <div className="shopDetailsHeader">
        <h3> {seller?.firstName.toUpperCase()}</h3>
      </div>
      <div className="row">
        {/* Filters */}

        <div className="col-lg-2 col-md-2 largeScreenFilters">
          <div className="sellerCategoriesContent">
            <p>Categories</p>
            <div className="contentOverFlow">
              <FormGroup>
                {sellerCategoriesData.map((item: any, index: number) => (
                  <FormControlLabel
                    key={index.toString()}
                    control={
                      <Checkbox
                        defaultChecked={
                          filteredData.categories.indexOf(item) >= 0
                            ? true
                            : false
                        }
                        onChange={(e: any, checked: any) => {
                          let data = [...sellerCategoriesData];
                          let data1 = [...filteredData.categories];
                          data.forEach((ele: any, index: number) => {
                            if (item === ele) {
                              // updating filter in filterData
                              if (checked) {
                                data1.push(item);
                              } else {
                                let i = data1.indexOf(item);
                                data1.splice(i, 1);
                              }
                            }
                          });
                          // setSellerCategoriesData(data);
                          setFilteredData({
                            brand: filteredData.brand,
                            categories: data1,
                          });
                          dispatch(
                            getProductsStart({
                              sellerId: params?.id,
                              page,
                              filter: {
                                brand: filteredData.brand,
                                categories: data1,
                              },
                            })
                          );
                        }}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
          <div className="sellerCategoriesContent">
            <p>Brand</p>
            <div className="contentOverFlow">
              <FormGroup>
                {sellerBrandData.map((item: any, index: number) => (
                  <FormControlLabel
                    key={index.toString()}
                    control={
                      <Checkbox
                        defaultChecked={
                          filteredData.brand.indexOf(item) >= 0 ? true : false
                        }
                        onChange={(e: any, checked: any) => {
                          let data = [...sellerBrandData];
                          let data1 = [...filteredData.brand];
                          data.forEach((ele: any, index: number) => {
                            if (item === ele) {
                              // updating filter in filterData
                              if (checked) {
                                data1.push(item);
                              } else {
                                let i = data1.indexOf(item);
                                data1.splice(i, 1);
                              }
                            }
                          });
                          // setSellerCategoriesData(data);
                          setFilteredData({
                            categories: filteredData.categories,
                            brand: data1,
                          });
                          dispatch(
                            getProductsStart({
                              sellerId: params?.id,
                              page,
                              filter: {
                                categories: filteredData.categories,
                                brand: data1,
                              },
                            })
                          );
                        }}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
        </div>

        {/* Mobile View Filters */}
        <Drawer
          anchor={"bottom"}
          open={filter}
          onClose={() => setfilter(!filter)}
          className="drawer"
        >
          <MobileViewFilterWrapper>
            <FilterContainer>
              <FilterLabel>FILTERS</FilterLabel>
              <ClearButton onClick={() => setfilter(!filter)}>X</ClearButton>
            </FilterContainer>
            <div>
              <Accordion
                expanded={smallScreenFilter === "Categories"}
                onChange={handleSmallScreenFiltersExpand("Categories")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Categories-content"
                  id="Categories-header"
                >
                  <p className="sellerHeaders">Categories</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="contentOverFlow">
                    <FormGroup>
                      {sellerCategoriesData.map((item: any, index: number) => (
                        <FormControlLabel
                          key={index.toString()}
                          control={
                            <Checkbox
                              defaultChecked={
                                filteredData.categories.indexOf(item) >= 0
                                  ? true
                                  : false
                              }
                              onChange={(e: any, checked: any) => {
                                let data = [...sellerCategoriesData];
                                let data1 = [...filteredData.categories];
                                data.forEach((ele: any, index: number) => {
                                  if (item === ele) {
                                    // updating filter in filterData
                                    if (checked) {
                                      data1.push(item);
                                    } else {
                                      let i = data1.indexOf(item);
                                      data1.splice(i, 1);
                                    }
                                  }
                                });
                                // setSellerCategoriesData(data);
                                setFilteredData({
                                  brand: filteredData.brand,
                                  categories: data1,
                                });
                                dispatch(
                                  getProductsStart({
                                    sellerId: params?.id,
                                    page,
                                    filter: {
                                      brand: filteredData.brand,
                                      categories: data1,
                                    },
                                  })
                                );
                              }}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={smallScreenFilter === "Brand"}
                onChange={handleSmallScreenFiltersExpand("Brand")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Brand-content"
                  id="Brand-header"
                >
                  <p className="sellerHeaders">Brand</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="contentOverFlow">
                    <FormGroup>
                      {sellerBrandData.map((item: any, index: number) => (
                        <FormControlLabel
                          key={index.toString()}
                          control={
                            <Checkbox
                              defaultChecked={
                                filteredData.brand.indexOf(item) >= 0
                                  ? true
                                  : false
                              }
                              onChange={(e: any, checked: any) => {
                                let data = [...sellerBrandData];
                                let data1 = [...filteredData.brand];
                                data.forEach((ele: any, index: number) => {
                                  if (item === ele) {
                                    // updating filter in filterData
                                    if (checked) {
                                      data1.push(item);
                                    } else {
                                      let i = data1.indexOf(item);
                                      data1.splice(i, 1);
                                    }
                                  }
                                });
                                // setSellerCategoriesData(data);
                                setFilteredData({
                                  categories: filteredData.categories,
                                  brand: data1,
                                });
                                dispatch(
                                  getProductsStart({
                                    sellerId: params?.id,
                                    page,
                                    filter: {
                                      categories: filteredData.categories,
                                      brand: data1,
                                    },
                                  })
                                );
                              }}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <br />
            <br />
          </MobileViewFilterWrapper>
        </Drawer>
        <p className="shops_filterButton" onClick={() => setfilter(true)}>
          FILTER
          <FontAwesomeIcon icon={faFilter} />
        </p>
        {/* Shop Details */}
        <div className={"col-lg-10 col-md-10 ShopDetailsWrapper"}>
          <div className={"shopDetailsContent"}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Shop" value="1" />
                    {/* <Tab label="About" value="2" /> */}
                    <Tab label="Reviews" value="3" />
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  classes={{
                    root: "tabPanelRoot",
                  }}
                >
                  <>
                    <div className="row" style={{ marginBottom: "24px" }}>
                      {products.products?.length > 0 &&
                        products.products.map((item: any, index: number) =>
                          SingleProductLayout(item, index)
                        )}
                    </div>
                    <div>
                      <Pagination
                        onChange={(e: any, page: any) => {
                          dispatch(
                            getProductsStart({
                              sellerId: params?.id,
                              page,
                              filter: filteredData,
                            })
                          );
                          window.scroll(0, 0);
                        }}
                        count={products.totalPages}
                      />
                    </div>
                    <Modal
                      open={shareModelOpen}
                      onClose={() => {
                        setShareModelOpen(false);
                      }}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          bgcolor: "white",
                          borderRadius: "5px",
                          boxShadow: "24",
                          p: 4,
                          height: 240,
                          background: "white",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h3"
                        >
                          Share this listing
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            padding: "0.4rem",
                            alignSelf: "stretch",
                          }}
                        >
                          <p>Click on this icon to copy below link</p>
                          <CopyToClipboard
                            text={`${window.location.origin}/product-page/${
                              shareProductDetails.id
                            }/${titelize(shareProductDetails.title)}`}
                            onCopy={() => {
                              snack.success("Link copied to clipboard!");
                            }}
                          >
                            <Tooltip
                              title="Copy Below link"
                              style={{ marginRight: "5em" }}
                            >
                              <InsertLinkIcon
                                sx={{
                                  opacity: 1,
                                  cursor: "pointer",
                                  transition: "1s",
                                  stroke: "black",
                                  strokeWidth: 1,
                                  "&:hover": {
                                    opacity: 1,
                                    transform: "scale(1.05)",
                                    transition: "1s",
                                  },
                                }}
                              />
                            </Tooltip>
                          </CopyToClipboard>
                        </Box>
                        <Box
                          sx={{
                            width: 500,
                            maxWidth: "100%",
                          }}
                        >
                          <CopyToClipboard
                            text={`${window.location.origin}/product-page/${
                              shareProductDetails.id
                            }/${titelize(shareProductDetails.title)}`}
                            onCopy={() => {
                              snack.success("Link copied to clipboard!");
                            }}
                          >
                            <TextField
                              fullWidth
                              label="Link"
                              sx={{ input: { cursor: "pointer" } }}
                              InputProps={{
                                style: {
                                  borderRadius: "10px",
                                },
                              }}
                              disabled={true}
                              defaultValue={shareUrl}
                            />
                          </CopyToClipboard>
                        </Box>
                        <div
                          style={{
                            background: "#0000",
                            height: "100vh",
                            width: "100%",
                            display: "flex",
                            gap: "0.5em",
                            justifyContent: "center",
                            marginTop: "1em",
                          }}
                        >
                          <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                          </FacebookShareButton>

                          <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={40} round={true} />
                          </WhatsappShareButton>

                          <EmailShareButton url={shareUrl}>
                            <EmailIcon size={40} round={true} />
                          </EmailShareButton>

                          <RedditShareButton url={shareUrl}>
                            <RedditIcon size={40} round={true} />
                          </RedditShareButton>
                          <TwitterShareButton url={shareUrl}>
                            <TwitterIcon size={40} round={true} />
                          </TwitterShareButton>
                          <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon size={40} round={true} />
                          </LinkedinShareButton>
                        </div>
                      </Box>
                    </Modal>
                  </>
                </TabPanel>
                {/* <TabPanel value="2">Item Two</TabPanel> */}
                <TabPanel value="3">
                  {reviews?.length > 0 ? (
                    <>
                      <CustomReviewSection
                        reviews={reviews}
                        product={reviewProducts}
                      />
                    </>
                  ) : null}
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
