import axios from "api/axiosController";

export const getProducts = async (data:any) => {
  let uri = `product/search?search=${data.query}&page=1`;

    try {
      const res = await axios.get(uri);
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getProductByID = async (data:any) => {
  let uri = `product/${data}`;
    try {
      const res = await axios.get(uri);
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getFilterBasedProducts = async (data:any) => {
  const { categoryId } = data;
  let uri = '';

  let filterKeys = Object.keys (data.filter);

  let filterStr= `product/${categoryId}?`;

  for (let i = 0; i < filterKeys.length; i++) {
    if (!data.filter[filterKeys[i]])
      continue;
    if (i === 0) {
      filterStr=filterStr.concat (`${filterKeys[i]}=${data.filter[filterKeys[i]]}`);
    } else {
      filterStr=filterStr.concat (`&${filterKeys[i]}=${data.filter[filterKeys[i]]}`);
    }
  }
  uri = filterStr+'&priceMin=0&page=1';
  console.log (uri);
  // uri = `product/${categoryId}?color=${filter.color}&brand=${filter.brand}&material=${filter.material}&priceMin=${filter.priceMin}&priceMax=${filter.priceMax}&page=${1}`;

  try {
    const res = await axios.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const getProductImage = async (imageLinks: any) => {
  let uri = 'product/get-product-images';
  try {
    const res = await axios.post(uri, { imageLinks, });
    return res.data;
  } catch (e) {
    throw e;
  }
};

