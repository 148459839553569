import { IproductCategories } from "../types/productCategories";

import {
  Animals_and_Pet_Supplies,
  Anti_Perspirant,
  Apparel_and_Accessories,
  Art_and_Entertainment,
  Baby_and_toddler,
  Bussiness_and_Industrial,
  Cap,
  Ceremonial,
  Device,
  Ebook_Reader_Cases,
  Electronics,
  Ereader,
  Food_Beverages,
  Furnitures,
  Gaming,
  Green_house,
  Hardware,
  Health_and_Beauty,
  Jewelery,
  Kitchen,
  Luggage_and_Bags,
  Mature,
  Mobile_Prepaid_Cards,
  Office_supplies,
  Software,
  Sporting_Goods,
  Sunglasses,
  Tools,
  Toys,
  Vehicles_and_Parts,
  Video_console,
} from "assets2/images/images";

const productCategories: IproductCategories[] = [
  {
    id: 0,
    category: "Home & Kitchen",
    categoryImage: Green_house,
  },
  {
    id: 1,
    category: "Hardware",
    categoryImage: Tools,
  },
  {
    id: 2,
    category: "Video Games",
    categoryImage: Video_console,
  },
  {
    id: 3,
    category: "Mobile & Tablets",
    categoryImage: Device,
  },
  {
    id: 4,
    category: "Electronics",
    categoryImage: Electronics,
  },
  {
    id: 5,
    category: "Computers",
    categoryImage: Gaming,
  },
  {
    id: 6,
    category: "Kitchen",
    categoryImage: Kitchen,
  },
  {
    id: 7,
    category: "Sun Glasses",
    categoryImage: Sunglasses,
  },
  {
    id: 8,
    category: "Toys",
    categoryImage: Toys,
  },
  {
    id: 9,
    category: "Jewelery",
    categoryImage: Jewelery,
  },
  {
    id: 10,
    category: "Hats",
    categoryImage: Cap,
  },
  {
    id: 11,
    category: "HOME & KITCHEN",
    categoryImage: Green_house,
  },
  {
    id: 12,
    category: "HARDWARE",
    categoryImage: Tools,
  },
  {
    id: 13,
    category: "VIDEO GAMES",
    categoryImage: Video_console,
  },
  {
    id: 14,
    category: "Mobile & Tablets",
    categoryImage: Device,
  },
  {
    id: 15,
    category: "Electronics",
    categoryImage: Electronics,
  },
  {
    id: 16,
    category: "Computers",
    categoryImage: Gaming,
  },
  {
    id: 17,
    category: "Kitchen",
    categoryImage: Kitchen,
  },
  {
    id: 18,
    category: "Sun Glasses",
    categoryImage: Sunglasses,
  },
  {
    id: 19,
    category: "Toys",
    categoryImage: Toys,
  },
  {
    id: 20,
    category: "Jewelery",
    categoryImage: Jewelery,
  },
  {
    id: 21,
    category: "Hats",
    categoryImage: Cap,
  },
];

export default function makeCategories(categories: any) {
  let _categories = [];
  let images = [];

  for (let i = 0; i < categories.length; i++) {
    switch (categories[i].categoryName) {
      case " Animals & Pet Supplies":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Animals_and_Pet_Supplies,
        });
        break;

      case " Arts & Entertainment":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Art_and_Entertainment,
        });
        break;

      case " Apparel & Accessories":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Apparel_and_Accessories,
        });
        break;

      case " Baby & Toddler":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Baby_and_toddler,
        });
        break;

      case " Business & Industrial":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Bussiness_and_Industrial,
        });
        break;

      case " Electronics":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Electronics,
        });
        break;

      case "Mobile Phone Pre-Paid Cards":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Mobile_Prepaid_Cards,
        });
        break;
      case "E-Book Reader Cases":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Ebook_Reader_Cases,
        });
        break;

      case "E-Book Reader Screens & Screen Digitizers":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Ereader,
        });
        break;

      case " Food, Beverages & Tobacco":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Food_Beverages,
        });
        break;

      case " Furniture":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Furnitures,
        });
        break;

      case " Hardware":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Hardware,
        });
        break;

      case " Health & Beauty":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Health_and_Beauty,
        });
        break;
      case "Anti-Perspirant":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Anti_Perspirant,
        });
        break;
      case " Home & Garden":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Green_house,
        });
        break;

      case " Luggage & Bags":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Luggage_and_Bags,
        });
        break;
      case " Mature":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Art_and_Entertainment,
        });
        break;
      case " Media":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Mature,
        });
        break;

      case " Office Supplies":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Office_supplies,
        });
        break;
      case " Religious & Ceremonial":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Ceremonial,
        });
        break;
      case " Software":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Software,
        });
        break;
      case " Sporting Goods":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Sporting_Goods,
        });
        break;
      case " Toys & Games":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Toys,
        });
        break;
      case " Vehicles & Parts":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: Vehicles_and_Parts,
        });
        break;

      default:
        break;
    }
  }
  return _categories;
}

export { productCategories };
