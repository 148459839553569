import { _axiosInstance } from "api/axiosController";
export const getSellers = async () => {
  let uri = `seller/v1/sellers`;

    try {
      const res = await _axiosInstance.get(uri);
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getAllProduct = async (data:string) => {
  let uri = `seller/v1/seller-pagination-products`;
    try {
      const res = await _axiosInstance.post(uri,data);
      return res.data;
    } catch (e) {
      throw e;
    }
};

