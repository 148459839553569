import { call, put, takeLatest } from "redux-saga/effects";
import {
  searchDataStart,
  searchDataSuccess,
  searchDataFailure,
  getProductsStartBasedOnFilters,
  getProduct,
  getProductSuccess,
  getProductFailure
} from 'redux/product';
import { getFilterBasedProducts, getProducts, getProductByID } from "redux/services/product";

function* productSagaWatcher() {
  yield takeLatest(
    [searchDataStart.type,
    getProduct.type,
    getProductsStartBasedOnFilters.type],
    productWorker,
  );
}

function* productWorker(action: any): any {
  try {
    switch (action.type) {
      case searchDataStart.type: {
        const response: any = yield call(getProducts, action.payload);
        yield put (searchDataSuccess({ products: response.ResponseBody }));
      }
        break;

      case getProductsStartBasedOnFilters.type: {
        const response: any = yield call(getFilterBasedProducts, action.payload);
        yield put (searchDataSuccess({ products: response.ResponseBody }));
      }
        break;
      case getProduct.type: {
        const response: any = yield call(getProductByID, action.payload.id);
        yield put (getProductSuccess({ product: response.ResponseBody }))
        console.log(response)
      }
        break;


      default:
        break;
    }
  } catch (err:any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === searchDataStart.type) {
      yield put(searchDataFailure);
    }
  }
}

export default productSagaWatcher;
