import snack from "components/wrapper/snack";
import jwtDecode from "jwt-decode";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getSellersStart,
  getSellersSuccess,
  cancelSellers,
  getProductsStart,
  getProductsSuccess,
  cancelProducts,
} from "redux/sellers";
import {
  getAllProduct,
  getSellers,
} from "redux/services/sellers";

function* sellersSagaWatcher() {
  yield takeLatest(
    [getSellersStart.type, getProductsStart.type],
    sellersWorker
  );
}

function* sellersWorker(action: any): any {
  try {
    switch (action.type) {
      case getSellersStart.type:
        {
          console.log("hi there");
          const response: any = yield call(getSellers);
          yield put(getSellersSuccess(response.ResponseBody));
        }
        break;
      case getProductsStart.type:
        {
          const response: any = yield call(getAllProduct, action.payload);
          yield put(getProductsSuccess(response.ResponseBody));
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getSellersStart.type) {
      yield put(cancelSellers);
    }

    if (action.type === getProductsStart.type) {
      yield put(cancelProducts);
    }
  }
}

export default sellersSagaWatcher;
