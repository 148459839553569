import snack from "components/wrapper/snack";
import jwtDecode from "jwt-decode";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  createReviewStart,
  createReviewSuccess,
  createReviewFailure,
  getReviewByProduct,
  getReviews,
  getReviewBySellerId,
  getReviewBySellerIdSuccess,
  getReviewByProductSuccess,
} from "redux/rating";
import {
  createReview,
  getAllReview,
  getAllReviewByProduct,
  getAllReviewBySellerId,
} from "redux/services/rating";

function* ratingSagaWatcher() {
  yield takeLatest([createReviewStart.type, getReviews.type,getReviewByProduct.type, getReviewBySellerId.type], ratingWorker);
}

function* ratingWorker(action: any): any {
  try {
    switch (action.type) {
      case createReviewStart.type:
        {
          const { history, prevPath } = action.payload;
          if (localStorage.getItem("access-token")) {
            const response: any = yield call(createReview, action.payload);
            yield put(createReviewSuccess({ reviews: response.ResponseBody }));
            snack.success("Product review created");
            history.goBack();
          }
        }
        break;
      case getReviewByProduct.type:
        {
          const response: any = yield call(
            getAllReviewByProduct,
            action.payload
          );
          yield put(getReviewByProductSuccess({ productReview: response.ResponseBody })); 
        }
        break;
        case getReviewBySellerId.type:
          {
            const response: any = yield call(
              getAllReviewBySellerId,
              action.payload
            );
            yield put(getReviewBySellerIdSuccess( response.ResponseBody)); 
          }
          break;
      case getReviews.type:
        {
          if (localStorage.getItem("access-token")) {
            const response: any = yield call(getAllReview, action.payload);
            yield put(createReviewSuccess({ reviews: response.ResponseBody }));
          }
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === createReviewStart.type) {
      yield put(createReviewFailure);
    }
  }
}

export default ratingSagaWatcher;
