import jwt from 'jwt-decode';
import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { getCartItemsStart } from 'redux/cart';
import { getWishListItems, removeFromWishList, updateWishListItems } from 'redux/services/wishList';

import {
  addToWishListFailure,
  addToWishListStart,
  addToWishListSuccess,
  getWishListItemsStart,
  removeFromWishListStart,
  removeFromWishListSuccess
} from 'redux/wishlist';
import { getAccessToken } from 'utils/auth';

function* wishListSagaWatcher() {
  yield takeLatest(
    [addToWishListStart.type,
    removeFromWishListStart.type,
    getWishListItemsStart.type],
    wishListWorker,
  );
}
function* wishListWorker(action: any): any {
  try {
    switch (action.type) {
      case addToWishListStart.type: {
        if (getAccessToken()) {
          yield call(updateWishListItems, action.payload);
          yield put(getCartItemsStart());
          yield put(getWishListItemsStart());
        } else {
          console.error('Unauthorized user');
        }

        break;
      }

      case removeFromWishListStart.type: {
        if (getAccessToken()) {
          const response: any = yield call(removeFromWishList, action.payload);
          yield put(removeFromWishListSuccess(response.ResponseBody));
          yield put(getWishListItemsStart());
        }

        break;
      }

      case getWishListItemsStart.type: {
        const response: any = yield call(getWishListItems);
        yield put(addToWishListSuccess({ wishListItems: response.ResponseBody }));

        break;
      }

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === addToWishListStart.type) {
      yield put(addToWishListFailure());
    }
  }
}

export default wishListSagaWatcher;
