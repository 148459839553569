import { useConnector } from "react-instantsearch-hooks-web";
import connectAutocomplete from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";

import type {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";
import { useEffect } from "react";

export type UseAutocompleteProps = AutocompleteConnectorParams;

export function useAlgoliaAutocomplete(props?: UseAutocompleteProps) {
  return useConnector<
    AutocompleteConnectorParams,
    AutocompleteWidgetDescription
  >(connectAutocomplete, props);
}
