// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SignInForm from "./signInForm";
import { CreateAccount } from "./signInForm";
import VerifyOTP from "./verifyOtp";
import { SignInUser } from "types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import "components/Account/style.css";
import "components/Layout/SiteContent/Support/style.css";
import "styles/common.css";
import "components/Layout/SiteContent/MyOrders/style.css";
import "components/Layout/Header/style.css";
import snack from "components/wrapper/snack";
import { useDispatch } from "react-redux";
import { appleloginStart, googleloginStart } from "redux/user";
import { FcGoogle } from "react-icons/fc";
import AppleIcon from "@mui/icons-material/Apple";
import { GoogleLogin } from "react-google-login";
import AppleLogin from "react-apple-login";
import { Logo } from "assets2/images/images";

import {
  GoogleButton,
  AppleButton,
  AuthContainer,
  IconContainer,
  LineWithOr,
  OrText,
  LogoAndTitleStyles,
  BrandLogoLink,
  BrandLogoImg,
  GoogleAuthContainerDiv,
  LoginWrapperDiv,
  SignupHeader,
} from "./styledComponents";

const SignIn: React.FC = () => {
  const userData: any = useSelector((state: RootState) => state.user);
  const location: any = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [user, setUser] = useState<SignInUser>({
    emailOrPhone: "",
    password: "",
    phoneNumber: "",
    countryCode: "",
    is2FAVerified: false,
  });

  useEffect(() => {
    if (location.state && location.state.msg) {
      setMsg(location.state.msg);
    }

    if (
      userData.accessToken &&
      userData.accessToken !== null &&
      userData.loggedIn
    ) {
      history.push("/");
    }
  }, []);

  const responseFromGoogle = (response: any) => {
    const token = response["tokenId"];
    if (location.state && location.state.buyNowFlow) {
      dispatch(
        googleloginStart({
          token,
          history,
          buyNowFlow: location.state.buyNowFlow,
          redirect: location.state?.redirect,
        })
      );
    } else
      dispatch(
        googleloginStart({ token, history, redirect: location.state?.redirect })
      );
  };

  const errorFromGoogle = (response: any) => {
    snack.error("Google Sign in failed");
    history.push("/");
  };

  const responseFromApple = (response: any) => {
    // Process the response from Apple Sign-In
    console.log("Apple Sign-In Response:", response);

    if (response && response.authorization) {
      if (location.state && location.state.buyNowFlow) {
        dispatch(
          appleloginStart({
            response,
            history,
            buyNowFlow: location.state.buyNowFlow,
            redirect: location.state?.redirect,
          })
        );
      } else {
        dispatch(
          appleloginStart({
            response,
            history,
            redirect: location.state?.redirect,
          })
        );
      }
    }
  };

  const errorFromApple = (error: any) => {
    // Handle Apple Sign-In error
    console.error("Apple Sign-In Error:", error);
  };
  return (
    <Grid direction="column" container alignItems="center">
      <div className="signInWrapper">
        <>
          {/* Desktop View */}
          {/* Logo and Title */}
          <Grid item className="hideMobileView">
            <LogoAndTitleStyles>
              <BrandLogoLink
                onClick={() => history.push("/")}
                className="brand-logo mr-3"
              >
                <BrandLogoImg src={Logo} alt="Brand Logo" />
              </BrandLogoLink>
            </LogoAndTitleStyles>
          </Grid>

          {/* Sign in Form */}
          <Grid item className="hideMobileView">
            {!userData.verified ? (
              <>
                {msg ? (
                  <div className={"verifyEmailMsg"}>
                    <p>{msg}</p>
                  </div>
                ) : null}
                <SignInForm user={user} setUser={setUser} />
              </>
            ) : (
              <VerifyOTP user={userData.user} />
            )}
          </Grid>

          <LineWithOr className="hideMobileView">
            <OrText>or</OrText>
          </LineWithOr>
        </>

        {/* Sign In with Google / Apple */}
        <Grid item>
          <AuthContainer>
            <SignupHeader>
              Sign up to receive 5% off, coupons and discounts!
            </SignupHeader>
            <GoogleAuthContainerDiv className="googleAuthContainer">
              {/* Google Sign in */}
              <LoginWrapperDiv className="googleAndAppleLoginWrapper">
                <GoogleLogin
                  clientId="337705274204-gac97kappr7imkhg32ijarfdgv0jfp8o.apps.googleusercontent.com"
                  onSuccess={responseFromGoogle}
                  onFailure={errorFromGoogle}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <GoogleButton
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="authProviderText"
                    >
                      <IconContainer>
                        <FcGoogle className="authProviderIcon" />
                      </IconContainer>
                      Sign in with Google
                    </GoogleButton>
                  )}
                />
              </LoginWrapperDiv>

              {/* Apple Sign In */}
              <LoginWrapperDiv className="googleAndAppleLoginWrapper">
                <AppleLogin
                  clientId="com.paysfer.apple-signin.sid"
                  scope="name email"
                  redirectURI="https://paysfer.com/signin"
                  responseType="code id_token"
                  responseMode="form_post"
                  state="state_value"
                  nonce="nonce_value"
                  usePopup
                  callback={responseFromApple}
                  render={(renderProps) => (
                    <AppleButton
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="authProviderText"
                    >
                      <IconContainer>
                        <AppleIcon className="authProviderIcon" />
                      </IconContainer>
                      Sign in with Apple
                    </AppleButton>
                  )}
                />
              </LoginWrapperDiv>
            </GoogleAuthContainerDiv>
          </AuthContainer>
        </Grid>

        {/* Create account */}
        <Grid item>
          <CreateAccount
            createAccountClick={() => history.push("/users/buyer/register")}
          />
        </Grid>
      </div>
    </Grid>
  );
};

export default SignIn;
