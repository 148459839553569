import { call, put, takeLatest } from "redux-saga/effects";
import {
  getDropdownCategoriesFetch,
  getDropdownCategoriesSuccess,
  getDropdownCategoriesFailure,
} from "redux/dropdownCategories";
import getDropdownItems from "../../api/dropdown";

function* dropdownCategoriesSagaWorker(): any {
  try {
    // Call the async function directly
    const data = yield call(() => getDropdownItems());
    // Dispatch the success action with the received data
    yield put(getDropdownCategoriesSuccess(data));
  } catch (error) {
    // Dispatch the failure action if an error occurs
    yield put(getDropdownCategoriesFailure());
  }
}

function* dropdownCategoriesSaga() {
  yield takeLatest(
    "dropdownCategories/getDropdownCategoriesFetch",
    dropdownCategoriesSagaWorker
  );
}

export default dropdownCategoriesSaga;
