import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  products : any;
  product: {};
  image: object[];
}

const initialState: State = {
  isLoading: true,
  error: false,
  products: [],
  product:{},
  image: []
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    searchDataStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    searchDataSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      products : action.payload.products
    }),
    searchDataFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getProductsStartBasedOnFilters: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
    getProduct: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
    getProductSuccess: (state:State, action) => ({
      ...initialState,
      isLoading: false,
      product: action.payload.product
    }),
    getProductFailure: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
    getImageStart: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
    getImageSuccess: (state:State, action) => ({
      ...initialState,
      isLoading: false,
      image: action.payload.image
    }),
    getImageFailure: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
  }
});

export const {
  searchDataStart,
  searchDataSuccess,
  searchDataFailure,
  getProductsStartBasedOnFilters,
  getProduct,
  getProductSuccess,
  getProductFailure,
  getImageStart,
  getImageSuccess,
  getImageFailure
} = productSlice.actions;
export default productSlice.reducer;
