import { createSlice } from "@reduxjs/toolkit";

interface State {
  sellers: Array<Object>;
  seller: any;
  sellerFilters: any;
  products: any;
  error: boolean;
  isLoading: boolean;
}

const initialState: State = {
  sellers: [],
  seller: {
    _id: "",
    email: "",
    firstName: "",
    lastName: "",
    sellerId: "",
  },
  sellerFilters: { categories: [], brand: [] },
  products: {
    products: [],
    page: 1,
    totalCount: 0,
    totalPages: 0,
  },
  error: false,
  isLoading: false,
};

export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    getSellersStart: (state: State) => ({
      ...state,
      error: false,
      isLoading: true,
    }),
    getSellersSuccess: (state: State, action) => ({
      ...state,
      sellers: action.payload,
      isLoading: false,
    }),
    cancelSellers: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getProductsStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getProductsSuccess: (state: State, action) => {
      return {
        ...initialState,
        seller: action.payload.sellerDetails,
        products: {
          products: action.payload.products,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        },
        isLoading: false,
      };
    },

    cancelProducts: (state: State, action) => ({
      ...initialState,
      error: true,
      isLoading: false,
    }),
  },
});

export const {
  getSellersStart,
  getSellersSuccess,
  cancelSellers,
  getProductsStart,
  getProductsSuccess,
  cancelProducts,
} = shopsSlice.actions;

export default shopsSlice.reducer;
