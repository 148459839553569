import styled from "styled-components";
import { Link } from "react-router-dom";

export const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: grey;
  border-radius: 30px;
  border-width: 1.7px;
  width: full;
  padding: 6px;
  background-color: white;
  font-size: 12px;
  color: black;
  &:hover {
    background: rgba(211, 211, 211, 0.3);
  }
  cursor: pointer;
`;

//  export const FacebookButton = styled.button`
//   display: flex;
//   align-items: center;
//   border-radius: 20px;
//   border-width: 1.7px;
//   justify-content: center;
//   padding: 6px;
//   background-color: white;
//   color: black;
//   font-size: 18px;
//   font-weight: bold;
//   &:hover {
//     background: rgba(211, 211, 211, 0.3);
//   }
//   cursor: pointer;
// `;

export const AppleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: grey;
  border-radius: 30px;
  border-width: 1.7px;
  padding: 6px;
  background-color: white;
  font-size: 12px;
  color: black;
  &:hover {
    background: rgba(211, 211, 211, 0.3);
  }
  cursor: pointer;
`;

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding-top: 10px;
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const LineWithOr = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 500px;
  width: 100%;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #000;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

export const OrText = styled.span`
  padding: 0 10px;
  font-size: 18px;
  font-weight: bold;
`;

export const LogoAndTitleStyles = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin: 10px;
`;

export const BrandLogoLink = styled(Link)`
  background-color: black;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  align-content: center;
`;

export const BrandLogoImg = styled.img`
  cursor: pointer;
`;

export const GoogleAuthContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

export const LoginWrapperDiv = styled.div`
  display: flex;
  height: 48px;
  justify-content: center;
`;

export const SignupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
  font-weight: bold;
`
