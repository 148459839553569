import { createSlice } from '@reduxjs/toolkit';
import { getAccessToken } from 'utils/auth';

interface State {
  isLoading: boolean;
  error: boolean;
  wishList: any;
  wishlistBanner: number;
}

const initialState = {
  isLoading: true,
  error: false,
  wishList: [],
  wishlistBanner: 0,
};

export const wishListSlice = createSlice ({
  name: 'wishList',
  initialState,
  reducers: {
    addToWishListStart: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
    addToWishListSuccess: (state: State, action) => {
      state.wishList = action.payload.wishListItems;
      state.wishlistBanner = state.wishList.length
      state.isLoading = false;
    },
    addToWishListFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    removeFromWishListStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    removeFromWishListSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
    }),
    removeFromWishListFailure: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getWishListItemsStart: (state: State) => ({
      ...state,
      isLoading: true
    }),
    clearWishListData: (state:State) => ({
      ...state,
      wishListItems : [],
      isLoading : false,
      error : false
    })
  },
});

export const {
  addToWishListStart,
  addToWishListSuccess,
  addToWishListFailure,
  removeFromWishListStart,
  removeFromWishListSuccess,
  removeFromWishListFailure,
  getWishListItemsStart,
  clearWishListData
} = wishListSlice.actions;

export default wishListSlice.reducer;
