import ShopDetails from "components/Layout/SiteContent/ShopDetails";
import ThankYou from "components/Layout/SiteContent/ThankYou";
import LazyLoadWrapper from "components/wrapper/lazyLoadWrapper";
// @ts-nocheck
import React from "react";
import { route } from "types/routes";
import ROUTE_PATHS from "./routesPath";
const PaymentSection = React.lazy(
  () => import("components/Layout/SiteContent/Payment")
);
const PaymentStatus = React.lazy(
  () => import("components/Layout/SiteContent/Payment/PaymentStatus")
);
const BuyerSignUp = React.lazy(
  () => import("components/Layout/SiteContent/Signup/buyer")
);
const SellerSignUp = React.lazy(
  () => import("components/Layout/SiteContent/Signup/seller")
);
const SignIn = React.lazy(() => import("components/Layout/SiteContent/Signin"));
const VerifyEmail = React.lazy(() => import("components/Account/verifyEmail"));
const ResendEmail = React.lazy(() => import("components/Account/resendEmail"));
const ForgotPassword = React.lazy(
  () => import("components/Account/forgotPassword")
);
const ResetPasswordForm = React.lazy(
  () => import("components/Account/forgotPassword/resetPasswordForm")
);
const Blog = React.lazy(
  () => import("components/Layout/SiteContent/Products/blog")
);
const ProductPage = React.lazy(
  () => import("components/Layout/SiteContent/Product")
);
const WishList = React.lazy(
  () => import("components/Layout/SiteContent/WishList")
);
const GiftCard = React.lazy(
  () => import("components/Layout/SiteContent/GiftCard")
);
const AffSignUp = React.lazy(
  () => import("components/Layout/SiteContent/Affliate/signup")
);
const AffSignIn = React.lazy(
  () => import("components/Layout/SiteContent/Affliate/signin")
);
const ContactUs = React.lazy(
  () => import("components/Layout/SiteContent/ContactUs")
);
const AboutUs = React.lazy(
  () => import("components/Layout/SiteContent/AboutUs")
);
const AffiliateHome = React.lazy(
  () => import("components/Layout/SiteContent/Affliate/affiliateHome")
);
const Home = React.lazy(() => import("components/Layout/SiteContent/Home"));
const ShoppingCart = React.lazy(
  () => import("components/Layout/SiteContent/ShoppingCart")
);
const ProductCategories = React.lazy(
  () => import("components/Layout/SiteContent/ProductCategories")
);
const ExploreProductsPage = React.lazy(
  () => import("components/Layout/SiteContent/ExploreProductsPage")
);
const PaymentProcess = React.lazy(
  () => import("components/Layout/SiteContent/PaymentProcess")
);
const OrderTracking = React.lazy(
  () => import("components/Layout/SiteContent/OrderTracking")
);
const MyOrders = React.lazy(
  () => import("components/Layout/SiteContent/MyOrders")
);
const AccountSettings = React.lazy(
  () => import("components/Layout/SiteContent/AccountSettings")
);
const EditAddress = React.lazy(
  () => import("components/Layout/SiteContent/PaymentProcess/editAddress")
);
const PayPalCheckout = React.lazy(
  () => import("components/Layout/SiteContent/Payment/PayPalCheckout")
);
const AccountSettingType = React.lazy(
  () =>
    import("components/Layout/SiteContent/AccountSettings/accountSettingType")
);
const OnBoarding = React.lazy(
  () => import("components/DirectSellers/onBoarding")
);
const VerifyOTP = React.lazy(
  () => import("components/Layout/SiteContent/Signup/buyer/verifyOtp")
);
const SearchResults = React.lazy(
  () =>
    import("components/Layout/SiteContent/ExploreProductsPage/searchResults")
);
const RatingReview = React.lazy(
  () => import("components/Layout/SiteContent/RatingReview")
);
const Addresses = React.lazy(
  () => import("components/Layout/SiteContent/Addresses")
);
const CreateAddress = React.lazy(
  () => import("components/Layout/SiteContent/Addresses/createAddress")
);
const EditSavedAddress = React.lazy(
  () => import("components/Layout/SiteContent/Addresses/editAddress")
);
const VerifyEmailOTPForm = React.lazy(
  () => import("components/Layout/SiteContent/Signup/buyer/verifyEmailOTP")
);
const PaymentMethods = React.lazy(
  () => import("components/Layout/SiteContent/Payment/PaymentMethods")
);
const MySubscriptions = React.lazy(
  () => import("components/Layout/SiteContent/MySubscriptions")
);
const PrivacyPolicy = React.lazy(
  () => import("components/Layout/SiteFooter/PrivacyPolicy")
);
const CookiePolicy = React.lazy(
  () => import("components/Layout/SiteFooter/CookiePolicy")
);
const StripePaymentStatus = React.lazy(
  () => import("components/Layout/SiteContent/Payment/StripePaymentStatus")
);
const Support = React.lazy(
  () => import("components/Layout/SiteContent/Support")
);
const ReturnPolicy = React.lazy(
  () => import("components/Layout/SiteFooter/ReturnPolicy")
);
const ShopList = React.lazy(
  () => import("components/Layout/SiteContent/ShopList")
);
const ELECTRONICS_SUBSHOP = React.lazy(
  () =>
    import(
      "components/Layout/SiteContent/ExploreProductsPage/Electronics/Electronics"
    )
);
const SHOES_SUBSHOP = React.lazy(
  () => import("components/Layout/SiteContent/ExploreProductsPage/Shoes/Shoes")
);
const JEWELRY_SUBSHOP = React.lazy(
  () =>
    import("components/Layout/SiteContent/ExploreProductsPage/Jewelry/Jewelry")
);
const AUTO_SUBSHOP = React.lazy(
  () => import("components/Layout/SiteContent/ExploreProductsPage/Auto/Auto")
);
const HOME_AND_GARDEN = React.lazy(
  () =>
    import(
      "components/Layout/SiteContent/ExploreProductsPage/HomeAndGarden/HomeAndGarden"
    )
);
const REFURBISHED_SUBSHOP = React.lazy(
  () =>
    import(
      "components/Layout/SiteContent/ExploreProductsPage/Refurbished/Refurbished"
    )
);
// const ShopDetails = React.lazy(() => import('components/Layout/SiteContent/ShopDetails'));

const routes: route[] = [
  {
    path: ROUTE_PATHS.SHOPLIST,
    component: (
      <LazyLoadWrapper>
        <ShopList />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SHOP,
    component: (
      <LazyLoadWrapper>
        <ShopDetails />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.BUYER_REGISTER,
    component: (
      <LazyLoadWrapper>
        <BuyerSignUp />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SELLER_REGISTER,
    component: (
      <LazyLoadWrapper>
        <SellerSignUp />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SIGN_IN,
    component: (
      <LazyLoadWrapper>
        <SignIn />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RESEND_EMAIL,
    component: (
      <LazyLoadWrapper>
        <ResendEmail />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.VERIFY_TOKEN,
    component: (
      <LazyLoadWrapper>
        <VerifyEmail />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD_OTP,
    component: (
      <LazyLoadWrapper>
        <ResetPasswordForm />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD_EMAIL,
    component: (
      <LazyLoadWrapper>
        <ResetPasswordForm />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    component: (
      <LazyLoadWrapper>
        <ForgotPassword />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_CATEGORY_ITEM,
    component: (
      <LazyLoadWrapper>
        <ProductPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.BLOG,
    component: (
      <LazyLoadWrapper>
        <Blog />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CART,
    component: (
      <LazyLoadWrapper>
        <ShoppingCart />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_PAGE,
    component: (
      <LazyLoadWrapper>
        <ProductPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.WISHLIST,
    component: (
      <LazyLoadWrapper>
        <WishList />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SUPPORT,
    component: (
      <LazyLoadWrapper>
        <Support />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYMENT_PROCESS,
    component: (
      <LazyLoadWrapper>
        <PaymentProcess />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.GIFTCARD,
    component: (
      <LazyLoadWrapper>
        <GiftCard />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AFFILIATE_LOGIN,
    component: (
      <LazyLoadWrapper>
        <AffSignIn />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AFFILIATE_REGISTER,
    component: (
      <LazyLoadWrapper>
        <AffSignUp />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CONTACT_US,
    component: (
      <LazyLoadWrapper>
        <ContactUs />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ABOUT_US,
    component: (
      <LazyLoadWrapper>
        <AboutUs />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.VERIFY_EMAIL_OTP,
    component: (
      <LazyLoadWrapper>
        <VerifyEmailOTPForm />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AFFILIATE_HOME,
    component: (
      <LazyLoadWrapper>
        <AffiliateHome />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SPECIAL_OFFER,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.EXCLUSIVE_COLLECTION,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.BEST_OFFERS,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_PAGE,
    component: (
      <LazyLoadWrapper>
        <ProductPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CATEGORIES,
    component: (
      <LazyLoadWrapper>
        <ProductCategories />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.EXPLORE_PRODUCTS,
    component: (
      <LazyLoadWrapper>
        <ExploreProductsPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.STRIPE_CHECKOUT,
    component: (
      <LazyLoadWrapper>
        <PaymentSection />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYMENT_STATUS,
    component: (
      <LazyLoadWrapper>
        <PaymentStatus />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.STRIPE_PAYMENT_STATUS,
    component: (
      <LazyLoadWrapper>
        <StripePaymentStatus />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYPAL_CHECKOUT,
    component: (
      <LazyLoadWrapper>
        <PayPalCheckout />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.TRACK_ORDER,
    component: (
      <LazyLoadWrapper>
        <OrderTracking />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.EDIT_ADDRESS,
    component: (
      <LazyLoadWrapper>
        <EditAddress />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_SETTINGS,
    component: (
      <LazyLoadWrapper>
        <AccountSettings />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RATING_REVIEW,
    component: (
      <LazyLoadWrapper>
        <RatingReview />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.MY_ORDERS,
    component: (
      <LazyLoadWrapper>
        <MyOrders />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYMENT_METHODS,
    component: (
      <LazyLoadWrapper>
        <PaymentMethods />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.MY_SUBSCRIPTION,
    component: (
      <LazyLoadWrapper>
        <MySubscriptions />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ADDRESSES,
    component: (
      <LazyLoadWrapper>
        <Addresses />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CREATE_ADDRESS,
    component: (
      <LazyLoadWrapper>
        <CreateAddress />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ADDRESS_EDIT,
    component: (
      <LazyLoadWrapper>
        <EditSavedAddress />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_SETTING_TYPE,
    component: (
      <LazyLoadWrapper>
        <AccountSettingType />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.DIRECT_SELLERS_ONBOARDING,
    component: (
      <LazyLoadWrapper>
        <OnBoarding />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SEARCH_RESULTS,
    component: (
      <LazyLoadWrapper>
        <SearchResults />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ELECTRONICS_SUBSHOP,
    component: (
      <LazyLoadWrapper>
        <ELECTRONICS_SUBSHOP />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SHOES_SUBSHOP,
    component: (
      <LazyLoadWrapper>
        <SHOES_SUBSHOP />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.JEWELRY_SUBSHOP,
    component: (
      <LazyLoadWrapper>
        <JEWELRY_SUBSHOP />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AUTO_SUBSHOP,
    component: (
      <LazyLoadWrapper>
        <AUTO_SUBSHOP />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.HOME_AND_GARDEN_SUBSHOP,
    component: (
      <LazyLoadWrapper>
        <HOME_AND_GARDEN />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.REFURBISHED_SUBSHOP,
    component: (
      <LazyLoadWrapper>
        <REFURBISHED_SUBSHOP />
      </LazyLoadWrapper>
    ),
    exact: true,
  },

  {
    path: ROUTE_PATHS.PRIVACY_POLICY,
    component: (
      <LazyLoadWrapper>
        <PrivacyPolicy />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.COOKIE_POLICY,
    component: (
      <LazyLoadWrapper>
        <CookiePolicy />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RETURN_POLICY,
    component: (
      <LazyLoadWrapper>
        <ReturnPolicy />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.HOME,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: false,
  },
  {
    path: ROUTE_PATHS.THANKYOU,
    component: (
      <LazyLoadWrapper>
        <ThankYou />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
];

export default routes;
