import styled from "styled-components";
export const ClearButton = styled.div`
  color: #76a9fa;
  cursor: pointer;
  font-size: 23px;
  margin-bottom: 0.5em;
  font-weight: bold;
`;

export const FilterLabel = styled.div`
  fontsize: 23px;
  margin-bottom: 0.5em;
  font-weight: bold;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileViewFilterWrapper = styled.div`
  margin: 0.5em 1em 0 1em;
  max-height: 300px;
  overflow-y: scroll;
`;

export const CardContainer = styled.div`
  padding: 0px 8px;
  border: 0px 1px 1px 1px solid black;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1em;
`;
