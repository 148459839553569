import { Link } from "@mui/material";
import axios from "api/axiosController";
import snack from "components/wrapper/snack";
import "../../../styles/common.css";
import "../../../styles/responsive-common.css";
import { useQuery, useSearch, useSearchContext } from "@sajari/react-hooks";
import { useHistory } from "react-router-dom";
import { isEmail } from "utils/validations";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { subscribeUserStart } from "redux/newsLetter";
import styled from "styled-components";
import {
  Facebook,
  Gpay,
  Instagram,
  I_pay,
  Linkedin,
  Logo,
  Pay_pal,
  Stripe,
  Twitter,
  Venmo,
} from "assets2/images/images";
import EmailSignup from "./EmailSignup";

const Footer: React.FC = () => {
  const history = useHistory();
  const { query, setQuery } = useQuery();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState({
    err: false,
    msg: "",
  });
  const { searched } = useSearchContext();
  const { results = [] } = useSearch();
  const handleChangeClicks = (item: any) => {
    window.scrollTo(0, 0);

    setQuery(item);
    history.push({
      pathname: "/search-results",
      state: {
        searched: searched,
        results: results,
      },
    });
  };
  // const validateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let msg: string = "",
  //     err: boolean = false;

  //   let email = e.target.value;

  //   if (email == "") {
  //     err = false;
  //     msg = "";
  //   }

  //   if (!isEmail(email)) {
  //     err = true;
  //     msg = "Please enter a valid email address";
  //   } else {
  //     err = false;
  //     msg = "";
  //   }

  //   setEmail(email);
  //   setError({
  //     err,
  //     msg,
  //   });
  // };

  const handleSubscribeNow = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(subscribeUserStart({ email }));
    setEmail("");
  };

  const onEmailSignup = async (email: string) => {
    try {
      const response = await axios.post(
        'sendgrid/add-contact',
        {
          email
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      snack.success('Successfully subscribed to mailing list');
    } catch (error) {
      snack.error('Unable to subscribe to mailing list');
      console.log(error);
    }
  }

  return (
    <footer>
      <div>
        <div className="container-fluid">
          <div className="footer-bootm-section">
            <div className="row" style={{ alignItems: "baseline" }}>
              <div className="col-lg-4 col-md-6 col-6">
                <h4 className="text-uppercase">CUSTOMER SERVICE</h4>
                <StyledUl>
                  <li>
                    <StyledLink
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push({ pathname: "/support" });
                      }}
                    >
                      Help Center
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push({ pathname: "/privacy-policy" });
                      }}
                    >
                      Privacy policy
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push({ pathname: "/return-policy" });
                      }}
                    >
                      Return policy
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push({ pathname: "/cookie-policy" });
                      }}
                    >
                      Cookie policy
                    </StyledLink>
                  </li>
                </StyledUl>
              </div>
              <div className="col-lg-4 col-md-6 col-6">
                <div className="quick-styledLink-wrap">
                  <h4 className="text-uppercase">QUICK LINKS</h4>
                  <StyledUl>
                    <li
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push({ pathname: "/" });
                      }}
                    >
                      <StyledLink>Home</StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={() => {
                          window.scrollTo(0, 0);
                          history.push({ pathname: "/categories" });
                        }}
                      >
                        Category{" "}
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={() => {
                          window.scrollTo(0, 0);
                          history.push({ pathname: "/about-us" });
                        }}
                      >
                        About Us
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={() => {
                          window.scrollTo(0, 0);
                          // history.push({ pathname: "/contact-us" });
                          history.push({ pathname: "/support" });
                        }}
                      >
                        Contact Us
                      </StyledLink>
                    </li>
                    {/* <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                          history.push({ pathname: "/affiliate-login" });
                        }}
                      >
                        Affiliate Login
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                          history.push({ pathname: "/affiliate-register" });
                        }}
                      >
                        Affiliate Sign Up
                      </Link>
                    </li> */}
                    <li>
                      <StyledLink
                        onClick={() => history.push({ pathname: "/blog" })}
                      >
                        Blog
                      </StyledLink>
                    </li>
                  </StyledUl>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                <EmailSignup onEmailSignup={onEmailSignup}/>
              </div>
            </div>
            <div className="footer-payment-section">
              <div className="row">
                <div className="col-md-12">
                  <Link className="footer-logo">
                    <img
                      src={Logo}
                      alt="Brand Logo"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                  <p>
                    E-commerce marketplace, sells all the categories of goods,
                    low vendor fees, 3-5% lower prices on goods, eco friendly,
                    rapidly growing, based in New York.
                  </p>
                  <div className="payment-mode d-flex">
                    <Link>
                      <img alt="Apple pay logo" src={I_pay} />
                    </Link>
                    <Link>
                      <img alt="Google pay logo" src={Gpay} />
                    </Link>
                    <Link>
                      <img alt="PayPal logo" src={Pay_pal} />
                    </Link>
                    <Link>
                      <img alt="Stripe logo" src={Stripe} />
                    </Link>
                    <Link>
                      <img alt="Venmo logo" src={Venmo} />
                    </Link>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="footer-customer-section">
                    <h4>CUSTOMER SERVICE</h4>
                    <Link>Paysfer eMart LLC</Link>
                    <div className="address-box">
                      <div className="address-text">
                        <i className="fas fa-map-marker-alt"></i> 154 West 14th
                        Street, New York, NY10011
                      </div>
                      <div className="address-text">
                        <Link>
                          <i className="far fa-envelope-open"></i>{" "}
                          info@paysfer.com
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-section">
            COPYRIGHT 2023 PAYSFER. All Rights Reserved. Developed by{" "}
            <Link>www.paysfer.com</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

// @media (max-width: 991px)

{/* <div className="newsletter-section">
  <div className="newsletter-left">
    <h4 className="text-uppercase">SUBSCRIBE NOW !</h4>
    <p>By Signing Up To Our Newsletter- Paysfer eMart.</p>
  </div>
  <div className="newsletter-right">
    <form onSubmit={handleSubscribeNow}>
      <div className="form-group">
        <input
          value={email}
          autoComplete="off"
          onChange={validateEmail}
          type="text"
          className="form-control"
          id="exampleFormControlInput1"
          placeholder=""
        />
        <span>{error.err ? error.msg : null}</span>
        <button className="btn btn-primary">Subscribe Now</button>
      </div>
    </form>
  </div>
</div> */}

const StyledLink = styled(Link)`
  font-weight: 400 !important;
  font-size: 0.85rem;
  color: white !important;
  &:hover {
    cursor: pointer;
  }

`

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 8px;
`

export default Footer;
