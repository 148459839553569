import styled from "@emotion/styled";
import { Verified_user } from "assets2/images/images";


const IndentedPTag = styled.p`
  white-space: pre-wrap;
`;
const CustomReviewSection = (props: any) => {
    const getUrl = (id:string) =>{
      let data =   props.product.filter((item:any)=>item['_id']== id);
      return data[0].productgroupimageurl1?data[0].productgroupimageurl1:"";
    }
  return (
    <div className="description-section custom-spacer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12 ">
            <h4>Ratings and Reviews:</h4>
            <br/>
        
          </div>
        </div>
        <div className="custom-scrollbar">
          {props.reviews.map((item: any) => (
            <div className="review-container">
              <div className="row">
                <div className="col-md-5 col-lg-5 ">
                {item.productId?(
                    <>
                    <img src={getUrl(item.productId)} height={'100px'} width={'100px'}/>
                    </>
                ):null}
                {
                item?.rating && (
                  <div className="product-ratings d-flex align-items-center">
                  <div className="star-rating d-flex">
                    <i
                      className={
                        item?.rating >= 1 ? "fas fa-star" : "far fa-star"
                      }
                    ></i>
                    <i
                      className={
                        item?.rating >= 2 ? "fas fa-star" : "far fa-star"
                      }
                    ></i>
                    <i
                      className={
                        item?.rating >= 3 ? "fas fa-star" : "far fa-star"
                      }
                    ></i>
                    <i
                      className={
                        item?.rating >= 4 ? "fas fa-star" : "far fa-star"
                      }
                    ></i>
                    <i
                      className={
                        item?.rating >= 5 ? "fas fa-star" : "far fa-star"
                      }
                    ></i>
                  </div>
                </div>
                )
              }
                  {/* <div className="user-review-title d-flex align-items-center">
                    <div className="user-review">
                      {item?.rating}
                      <i className="fas fa-star"></i>
                    </div>
                  </div> */}
                  <IndentedPTag>
                    {item.userName && `by ${item.userName}`}
                  </IndentedPTag>
                  <IndentedPTag>
                    {item.updatedAt &&
                      new Date(item?.updatedAt).toLocaleDateString("en-us", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                  </IndentedPTag>
                  <div className="user-status d-flex align-items-center">
                    <img alt="user" src={Verified_user} />
                    <p>{"Certified Buyer"}</p>
                  </div>
                </div>
                <div className="col-md-7 col-lg-7 " style={{position:"relative",top:"100px"}}>
                  <h4>{item.title}</h4>
                  <IndentedPTag>{item.review}</IndentedPTag>
                </div>
              </div>
              <br />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomReviewSection;
