// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import { Link, Badge, Modal, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import Slider from "react-slick";
import HamburgerMenu from "./hamburgerMenu";
import { useEffect, useState } from "react";
import { getCartItemsStart } from "redux/cart";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { getUserStart, logoutStart } from "redux/user";
import Search from "./search";
import { useQuery, useSearch, useSearchContext } from "@sajari/react-hooks";
import makeCategories from "utils/productCategories";
import getDropdownItems from "api/dropdown";

import "./style.css";
import "../../../styles/common.css";
import "../../../styles/responsive-common.css";
import { Heart, New_Logo, Shopping_cart, User } from "assets2/images/images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";

import DropdownComponent from "./DropdownComponent";
import { getDropdownCategoriesFetch } from "redux/dropdownCategories";

interface TopPickCategory {
  displayName: string;
  childData: any; // You might want to create a specific interface for childData
  // Add other properties if needed
}

const BREAKPOINTS = {
  TABLET: "992px",
  MOBILE: "768px",
};

const Header: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [open, setOpen] = useState(false);
  const [topPickCategories, setTopPickCategories] = useState<TopPickCategory>(
    []
  );
  // States for keeping track of opening and closing megamenu
  const [megaMenuVisible, setMegaMenuVisible] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { query, setQuery } = useQuery();
  const { results = [] } = useSearch();
  const { searched } = useSearchContext();
  let categories = useSelector(
    (state: RootState) => state.categories.categoryList
  );
  const cartBanner = useSelector((state: RootState) => state.cart.cartBanner);
  const wishlistBanner = useSelector(
    (state: RootState) => state.wishlist.wishlistBanner
  );
  const user: any = useSelector((state: RootState) => state.user.user);
  const accessToken: any = useSelector(
    (state: RootState) => state.user.accessToken
  );

  categories = categories.length > 0 ? makeCategories(categories) : [];
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  const showSubHeaders = location.pathname === "/";
  useEffect(() => (window.onresize = updateSize), []);

  useEffect(() => {
    getUserData();
  }, []);

  // Function to show megamenu
  const showMegaMenu = (menuItem: any) => {
    setActiveMenuItem(menuItem);
    setMegaMenuVisible(true);
  };

  // Function to hide megamenu
  const hideMegaMenu = () => {
    setActiveMenuItem(null);
    setMegaMenuVisible(false);
  };

  const getUserData = () => {
    dispatch(getUserStart());
    dispatch(getCartItemsStart());
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const settings = {
    dots: false,
    infinite: true,

    slidesToShow: 11,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          dots: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          dots: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleLogout = () => {
    dispatch(logoutStart());
    handleClose();

    history.push("/signin");
  };

  const userComp = () => {
    return (
      <>
        <Action
          onClick={handleMenu}
          style={{ cursor: "pointer" }}
          mobileHide={false}
        >
          <Icon alt="user-icon" src={User} />
          {accessToken && accessToken !== null ? (
            <ActionHeader mobileHide={true}>{user.firstName}</ActionHeader>
          ) : (
            <ActionHeader mobileHide={true}>Sign-in</ActionHeader>
          )}
        </Action>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {accessToken ? null : (
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: "/signin",
                  state: {
                    redirect: location.pathname,
                  },
                });
                handleClose();
              }}
            >
              Login
            </MenuItem>
          )}
          {accessToken ? (
            <MenuItem
              onClick={() => {
                history.push("/my-orders");
                handleClose();
              }}
            >
              My Orders
            </MenuItem>
          ) : null}
          {accessToken ? null : (
            <MenuItem
              onClick={() => {
                history.push("/users/buyer/register");
                handleClose();
              }}
            >
              Signup
            </MenuItem>
          )}
          {accessToken ? (
            <MenuItem
              onClick={() => {
                history.push("/account-settings");
                handleClose();
              }}
            >
              My Account
            </MenuItem>
          ) : null}
          {accessToken ? (
            <MenuItem
              onClick={() => {
                history.push("/addresses");
                handleClose();
              }}
            >
              My Addresses
            </MenuItem>
          ) : null}
          {accessToken ? (
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          ) : null}
        </Menu>
      </>
    );
  };

  const contactUs = () => {
    return (
      <Action onClick={() => history.push("/support")} mobileHide={true}>
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          color="secondary"
          // showZero
          style={{ cursor: "pointer" }}
        >
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#fff"
          >
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
          </SvgIcon>{" "}
        </Badge>
        <ActionHeader mobileHide={true}>Support</ActionHeader>
      </Action>
    );
  };

  const handleChangeCategory = (category: any) => {
    setQuery(category.categoryName);
    history.push({
      pathname: "/search-results",
      search: `?${"q"}=${category.categoryName}`,
      state: {
        searched: searched,
        results: results,
      },
    });
  };

  const handleTopChangeCategory = (categoryName: any) => {
    setQuery(categoryName);
    if (categoryName === "Electronics") {
      history.push({
        pathname: "/electronics",
        state: {
          searched: searched,
          results: results,
        },
      });
    } else if (categoryName === "Footwear") {
      history.push({
        pathname: "/footwear",
        state: {
          searched: searched,
          results: results,
        },
      });
    } else if (categoryName === "Jewelry") {
      history.push({
        pathname: "/jewelry",
        state: {
          searched: searched,
          results: results,
        },
      });
    } else if (categoryName === "Auto") {
      history.push({
        pathname: "/auto",
        state: {
          searched: searched,
          results: results,
        },
      });
    } else if (categoryName === "Home") {
      history.push({
        pathname: "/home",
        state: {
          searched: searched,
          results: results,
        },
      });
    } else if (categoryName === "Refurbished") {
      history.push({
        pathname: "/refurbished",
        state: {
          searched: searched,
          results: results,
        },
      });
    } else {
      history.push({
        pathname: "/search-results",
        search: `?${"q"}=${categoryName}`,
        state: {
          searched: searched,
          results: results,
        },
      });
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
  };

  const openMarketPlace = () => {
    window.open("https://marketplace.emart.ooo/", "_blank");
  };

  // fetches the data for dropdown menu
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDropdownItems();
        setTopPickCategories(data.ResponseBody);
      } catch (error) {
        console.error("Error fetching dropdown items:", error);
        fetchData();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    dispatch(getDropdownCategoriesFetch());
  }, [dispatch]);

  const dropdownItems = useSelector(
    (state: any) => state.dropdownCategories.dropdownCategories
  );
  return (
    <>
      <HeaderWrapper>
        <ContentWrapper>
          <div className="logo-wrap d-flex align-items-center">
            <Link onClick={() => history.push("/")} className="brand-logo mr-3">
              <LogoWrapper>
                <img
                  src={New_Logo}
                  alt="Brand Logo"
                  style={{ cursor: "pointer", width: "80%", margin: "0 auto" }}
                />
              </LogoWrapper>
            </Link>
            <div className="hamburger-text">
              {size.x > 1000 && "Categories"}
              <div className="position-hamburger">
                <HamburgerMenu
                  userName={
                    user.firstName ? user.firstName.toUpperCase() : null
                  }
                />
              </div>
            </div>
          </div>

          <div className="header-search mr-3 d-none d-lg-block">
            <div>
              <div className="form-group search-provider">
                <Search />
              </div>
            </div>
          </div>

          {/* Right side of header */}
          <ActionWrapper>
            <Action onClick={() => history.push("/wishlist")} mobileHide={true}>
              <Badge
                invisible={accessToken ? false : true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={wishlistBanner}
                color="secondary"
                // showZero
                sx={{
                  "& .MuiBadge-badge": {
                    minHeight: "54%",
                    width: "60%",
                    marginLeft: "20%",
                  },
                }}
                style={{ cursor: "pointer" }}
              >
                <Icon alt="wishlist" src={Heart} />
              </Badge>
              <ActionHeader mobileHide={true}>Wishlist</ActionHeader>
            </Action>
            <Action onClick={() => history.push("/cart")}>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={cartBanner}
                color="secondary"
                // showZero
                style={{ cursor: "pointer" }}
              >
                <Icon alt="cart" src={Shopping_cart} />
              </Badge>
              <ActionHeader mobileHide={true}>Cart</ActionHeader>
            </Action>
            {userComp()}
            {location.pathname.startsWith("/product-page") ? contactUs() : null}
            {size.x > 1000 && (
              <Link
                onClick={openMarketPlace}
                style={{
                  padding: "1rem",
                  cursor: "pointer",
                  marginRight: "1em",
                  backgroundColor: "#fff",
                  color: "#000",
                  borderRadius: "5px",
                  fontSize: "1.0rem",
                  fontWeight: "bold",
                }}
                className="btn"
              >
                SELL
              </Link>
            )}
            <Modal open={open} onClose={handleModalClose}>
              <Box sx={style}>
                <div>
                  Please email us at <Link>seller@paysfer.com</Link> with your
                  company info, types of products you sell, contact person and a
                  phone number.
                </div>
                <button className="btn btn-primary" onClick={handleModalClose}>
                  Close
                </button>
              </Box>
            </Modal>
          </ActionWrapper>
        </ContentWrapper>

        {/* Categories */}
        {showSubHeaders ? (
          <div className="mr-3 d-none d-lg-block">
            <div className="displayCategory">
              <CategoriesWrapper>
                {topPickCategories.map((item: TopPickCategory) => (
                  <Category
                    className="CategoryFontStyle"
                    onMouseEnter={() => showMegaMenu(item.displayName)}
                    onMouseLeave={hideMegaMenu}
                    onClick={() => {
                      hideMegaMenu();
                      handleTopChangeCategory(item.displayName);
                    }}
                  >
                    <p>{item.displayName}</p>
                    {megaMenuVisible && activeMenuItem === item.displayName && (
                      <div
                        className="mega-dropdown-menu"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="inner-dropdown-menu">
                          <DropdownComponent childData={item.childData} />
                        </div>
                      </div>
                    )}
                  </Category>
                ))}
                <Category
                  className="CategoryFontStyle"
                  onClick={() => handleTopChangeCategory("Refurbished")}
                >
                  <p>Refurbished</p>
                </Category>
              </CategoriesWrapper>
            </div>
          </div>
        ) : null}

        <div className="header-search d-block d-lg-none">
          <div
            className="form-group search-provider"
            style={{ height: "35px", marginBottom: "1em" }}
          >
            <Search />
          </div>
        </div>

        <section
          className="category-section d-block d-lg-none"
          style={{ background: "hsl(122, 24%, 25%)" }}
        >
          <div
            style={{ marginTop: "1em", display: "flex", overflowX: "scroll" }}
          >
            {topPickCategories.map((item: TopPickCategory) => (
              <div
                key={item.displayName.toString()}
                onClick={
                  item.action ||
                  (() => handleTopChangeCategory(item.displayName))
                }
              >
                <div
                  style={{
                    color: "white",
                    border: "0.5px solid white",
                    borderRadius: "10px",
                    padding: "15px",
                    display: "inline-block",
                    marginLeft: "0.5em",
                  }}
                >
                  <p style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                    {" "}
                    {item.displayName}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </HeaderWrapper>
    </>
  );
};

// Main Header Styles
const HeaderWrapper = styled.header`
  --icon-width: 30px;

  position: fixed;
  width: 100%;
  isolation: isolate;
  z-index: 100;
  background-color: hsl(122, 24%, 18%);

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    --icon-width: 25px;
    padding-top: 0.5rem;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    --icon-width: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  gap: 24px;
`;

const LogoWrapper = styled.div`
  display: grid;
  place-content: center;
  width: 250px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 150px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-right: 20px;
  }
`;

interface ActionProps {
  mobileHide?: boolean;
}

const Action = styled(Link)<ActionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => (props.mobileHide ? "none" : "flex")};
  }
`;

const Icon = styled.img`
  height: var(--icon-width);
  width: var(--icon-width);
`;

const SvgIcon = styled.svg`
  height: var(--icon-width);
  width: var(--icon-width);
`;

interface ActionHeaderProps {
  mobileHide?: boolean;
}

const ActionHeader = styled.span<ActionHeaderProps>`
  font-size: 12px;
  font-weight: bold;
  line-height: 13px;
  color: white;
  margin-top: 5px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => (props.mobileHide ? "none" : "block")};
  }
`;

// Categories Subheader Styles
const CategoriesWrapper = styled.div`
  display: flex;
  background-color: hsl(122, 24%, 25%);
`;

const Category = styled.div`
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  flex-grow: 1;

  &:hover {
    cursor: pointer;
  }
`;

export default Header;
