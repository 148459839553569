import { Routes } from "./routes";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch-hooks-web";
import {gapi} from 'gapi-script';

import "./styles/common.css";
import { useEffect } from "react";
// import { Autocomplete } from "autocomplete";

export const searchClient = algoliasearch(
  "ZYWA1Y4MN0",
  "156d9abfa026b631f0ed89adc2833292"
);
export const searchClientIndex = searchClient.initIndex("paysfer");

export const searchClientIndexSuggestions = searchClient.initIndex(
  "paysfer_query_suggestions"
);

const App = () => {

  useEffect(()=>{
    function start (){
      gapi.client.init({
        clientId: "337705274204-gac97kappr7imkhg32ijarfdgv0jfp8o.apps.googleusercontent.com",
        scope : ""
      })
    }
    gapi.load('client:auth2', start);
  }

  );
  return (
    <Provider store={store}>
      <InstantSearch searchClient={searchClient} indexName="paysfer">
        <Routes />
      </InstantSearch>
    </Provider>
  );
};

export default App;
